.view-container {
    margin-top: 9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.view-btn-spacing {
    margin-bottom: 5vh;
}

table {
    width: 100%;
}