.iaction-container {
    margin-top: 9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iaction-btn-spacing {
    margin-bottom: 5vh;
}

.iaction-inpt-spacing {
    margin-bottom: 3.5vh;
}

.iaction-add-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40vh;
}