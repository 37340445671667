.dash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.2vh;
    text-align: center;
}

table th {
    background-color: #f2f2f2;
    padding: 0.6vw;
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    width: 20%;
}

table td {
    padding: 0.6vw;
    border-bottom: 1px solid #ddd;
    width: 20%;
}


table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

.dash-wlcm {
    margin-top: 5vh;
    font-weight: bold;
    margin-bottom:  10vh;
}
    