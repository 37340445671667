.auth-container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    height: 100vh;
    background-color: rgb(247, 243, 235);
}

.auth-card-container {
    height: 34vw;
    width: 32vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 2.2vw;
    background-color: rgb(255, 255, 255);
    border: solid rgb(181, 139, 77);
    box-shadow: 0 0 20px rgb(181, 139, 77);
}

.auth-title {
    margin-bottom: 10vh;
    color: rgb(111, 78, 55);
}

.auth-inputs {
    width: 28vw;
    height: 7vh;
    border-radius: 3vw;
    text-align: center;
    margin-bottom: 5vh;
    border: 0.1vw solid gray;
    box-shadow: 0.5vw 0.5vw 1.3vw;
    font-size: 1.3vw;
    font-weight: bold;
    color: rgb(111, 78, 55);
}

.auth-inputs:focused, .auth-inputs:active {
    border: none;
}

.auth-btns {
    width: 11vw;
    height: 7.5vh;
    border-radius: 2vh;
    border: none;
    background-color: rgb(212, 163, 115);
    color: white;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.5s ease;
    margin-bottom: 2vh;
}

.auth-btns:hover {
    cursor: pointer;
    background-color: rgb(181, 139, 77);;
}

.auth-content {
    color: rgb(111, 78, 55);
    font-weight: bold;
    margin-top: 2%;
    max-width: 50vh;
    text-align: center;
}

.auth-alerts-container {
    margin-top: 4vh;
}


