.forbid {
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rsrc-title {
    margin-top: 5vh;
    text-align: center;
    font-weight: bold;
}

.rsrc-container {
    margin-top: 13vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rsrc-btns {
    margin-bottom: 8vh;
    width: 18vw;
}